import TokenSwapContainer from '../containers/TvpSwap'

const TvpSwap = () => {

  return (
    <TokenSwapContainer />
  );
}

export default TvpSwap; 

