import GuideContainer from '../containers/Guide';

const Guide = () => {
  return (
    <>
      <GuideContainer />
    </>
  );
};

export default Guide;
