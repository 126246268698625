// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/curved-images/curved6.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthorsHeaderIMG{\n\tbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tbackground-size: cover;\n}", "",{"version":3,"sources":["webpack://src/containers/Authors/AuthorsHeader.css"],"names":[],"mappings":"AAAA;CACC,yDAAmE;CACnE,sBAAsB;AACvB","sourcesContent":[".AuthorsHeaderIMG{\n\tbackground-image: url('../../assets/img/curved-images/curved6.jpg');\n\tbackground-size: cover;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
