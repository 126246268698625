import {
  SecNewListedArt1,
  SecNewListedArt2,
  SecNewListedArt3,
  SecNewListedArt4,
  SecNewListedArt5,
  SecNewListedArt6,
  SecNewListedArt7,
  SecNewListedArt8,
  SecNewListedArt9,
  SecNewListedArt10,
  SecNewListedArt11,
  SecNewListedArt12,
  SecNewListedArt13,
  SecNewListedArt14,
  SecNewListedArt15,
  SecNewListedArt16,
  SecNewListedArt17,
  SecNewListedArt18,
  SecNewListedArt19,
  SecNewListedArt20,
  SecNewListedArt21,
  SecNewListedArt22,
  SecNewListedArt23,
  SecNewListedArt24,
  SecNewListedArt25,
  SecNewListedArt26,
  SecNewListedArt27,
  SecNewListedArt28,
  SecNewListedArt29,
  SecNewListedArt30,
  SecNewListedTeam2,
  SecNewListedTeam3,
  SecNewListedAuthors1,
  SecNewListedAuthors2,
  SecNewListedAuthors3,
  SecNewListedAuthors4,
  SecNewListedAuthors5,
  SecNewListedAuthors6,
} from '../../utils/allImgs';

const data = [
  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/1',
    path4: '/itemdetails/1',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt1,
    img2: SecNewListedTeam2,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 2 In Stock',
    text2: 'The Brick',
    text3: 'The harmonious old red brick collection at the London street. ',
    artist: 'David Young',
    artistId: '@DG_London',
    created: '25 Jan, 2021 ',
    itemArtist: 'David Young',
    itemSize: '300 x 300',
    price: 20,
    highestBid: {
      bidderName: 'John Wright',
      bidderId: '@JW_ny',
      bidQty: 21,
      bidPrice: 23,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/2',
    path4: '/itemdetails/2',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt2,
    img2: SecNewListedTeam3,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors4,
    img6: SecNewListedAuthors3,
    text1: '1 of 5 In Stock',
    text2: 'Yellow Dot',
    text3: 'Black and white statues on the shelf',
    artist: 'Alicia Keys',
    artistId: '@AL_Keys',
    created: '4 Feb, 2021',
    itemArtist: 'Alicia Keys',
    itemSize: '300 x 300',
    price: 15,
    highestBid: {
      bidderName: 'Andrew Kim',
      bidderId: '@Andrew.kim',
      bidQty: 18,
      bidPrice: 20,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/3',
    path4: '/itemdetails/3',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt3,
    img2: SecNewListedAuthors5,
    img3: SecNewListedAuthors3,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors1,
    img6: SecNewListedAuthors4,
    text1: '2 of 3 In Stock',
    text2: 'Happy Birthday',
    text3: 'Happy Birthday! - from your Favorite',
    artist: 'Andrew Kim',
    artistId: '@Andrew.kim',
    created: '15 Feb, 2021',
    itemArtist: 'Andrew Kim',
    itemSize: '2500 x 2500',
    price: 30,
    highestBid: {
      bidderName: 'Jennifer Winston',
      bidderId: '@jwtn',
      bidQty: 32,
      bidPrice: 24,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/4',
    path4: '/itemdetails/4',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt4,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors2,
    img4: SecNewListedAuthors1,
    img5: SecNewListedAuthors4,
    img6: SecNewListedAuthors3,
    text1: '2 Of 4 In Stock',
    text2: 'Walking Cat',
    text3: 'The famous trickery cat walking through the streets of NYC',
    artist: 'Jennifer Winston',
    artistId: '@jwtn',
    created: '17 Feb, 2021',
    itemArtist: 'Jennifer Winston',
    itemSize: '2500 x 2500',
    price: 15,
    highestBid: {
      bidderName: 'Jethro Barrow',
      bidderId: '@barrowsj',
      bidQty: 16,
      bidPrice: 30,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },
  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/5',
    path4: '/itemdetails/5',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt5,
    img2: SecNewListedAuthors2,
    img3: SecNewListedAuthors4,
    img4: SecNewListedAuthors3,
    img5: SecNewListedAuthors2,
    img6: SecNewListedAuthors1,
    text1: '1 Of 3 In Stock',
    text2: 'Thinking Bird',
    text3: 'Birdy what are you thinking of?',
    artist: 'Jethro Barrow',
    artistId: '@barrowsj',
    created: '21 Feb, 2021',
    itemArtist: 'Jethro Barrow',
    itemSize: '2500 x 2500',
    price: 17,
    highestBid: {
      bidderName: 'Charles Ray',
      bidderId: '@king_ray',
      bidQty: 19,
      bidPrice: 40,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/6',
    path4: '/itemdetails/6',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt6,
    img2: SecNewListedAuthors3,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 of 3 In Stock',
    text2: 'B&W Dog',
    text3: "Singing dog's last drawing",
    artist: 'Charles Ray',
    artistId: '@king_ray',
    created: '22 Feb, 2021',
    itemArtist: 'Charles Ray',
    itemSize: '2500 x 2500',
    price: 35,
    highestBid: {
      bidderName: 'Cecil Haley',
      bidderId: '@ce_haley',
      bidQty: 37,
      bidPrice: 45,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/7',
    path4: '/itemdetails/7',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt7,
    img2: SecNewListedAuthors4,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors4,
    img5: SecNewListedAuthors2,
    img6: SecNewListedAuthors3,
    text1: '3 of 4 In Stock',
    text2: 'Depict',
    text3: 'Woman face drawn by one line',
    artist: 'Cecil Haley',
    artistId: '@ce_haley',
    created: '1 Mar, 2021',
    itemArtist: 'Cecil Haley',
    itemSize: '2500 x 2500',
    price: 40,
    highestBid: {
      bidderName: 'Elvia Hansen',
      bidderId: '@elv_paint',
      bidQty: 44,
      bidPrice: 55,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/8',
    path4: '/itemdetails/8',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt8,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 1 In Stock',
    text2: 'Dog Owner',
    text3: 'Drawing from a four years old',
    artist: 'Elvia Hansen',
    artistId: '@elv_paint',
    created: '4 Mar, 2021',
    itemArtist: 'Elvia Hansen',
    itemSize: '2500 x 2500',
    price: 50,
    highestBid: {
      bidderName: 'Kelly Escobar',
      bidderId: '@fwo_kelly',
      bidQty: 52,
      bidPrice: 66,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/9',
    path4: '/itemdetails/9',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt9,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 2 In Stock',
    text2: 'Pikazu',
    text3: 'Becoming the new pikazu #1',
    artist: 'Kelly Escobar',
    artistId: '@fwo_kelly',
    created: '16 Mar, 2021',
    itemArtist: 'Kelly Escobar',
    itemSize: '2500 x 2500',
    price: 25,
    highestBid: {
      bidderName: 'Dorothea Owens',
      bidderId: '@d_o_bird',
      bidQty: 26,
      bidPrice: 35,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/10',
    path4: '/itemdetails/10',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt10,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 3 In Stock',
    text2: 'Pink Crow',
    text3: 'Two-faced crow',
    artist: 'Dorothea Owens',
    artistId: '@d_o_bird',
    created: '16 Mar, 2021',
    itemArtist: 'Dorothea Owens',
    itemSize: '2500 x 2500',
    price: 35,
    highestBid: {
      bidderName: 'Bob Michaels',
      bidderId: '@bobby',
      bidQty: 36,
      bidPrice: 23,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/11',
    path4: '/itemdetails/11',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt11,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 4 In Stock',
    text2: 'Hallucinating',
    text3: 'Hallucinating with oceans, wavesm and NFTs',
    artist: 'Bob Michaels',
    artistId: '@bobby',
    created: '23 Mar, 2021',
    itemArtist: 'Bob Michaels',
    itemSize: '2500 x 2500',
    price: 60,
    highestBid: {
      bidderName: 'John Muller',
      bidderId: '@john',
      bidQty: 62,
      bidPrice: 78,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/12',
    path4: '/itemdetails/12',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt12,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '3 Of 4 In Stock',
    text2: 'Glowing Sphere',
    text3: 'Colourful mysterious Egg ',
    artist: 'John Muller',
    artistId: '@john',
    created: '30 Mar, 2021',
    itemArtist: 'John Muller',
    itemSize: '2500 x 2500',
    price: 35,
    highestBid: {
      bidderName: 'Terrance Kemp',
      bidderId: '@terr',
      bidQty: 37,
      bidPrice: 40,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/13',
    path4: '/itemdetails/13',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt13,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 2 In Stock',
    text2: 'Bear Beer',
    text3: 'Drunken bear holding a wine bottle',
    artist: 'Terrance Kemp',
    artistId: '@terr',
    created: '1 Apr, 2021',
    itemArtist: 'Terrance Kemp',
    itemSize: '2500 x 2500',
    price: 40,
    highestBid: {
      bidderName: 'Gabriel Santos',
      bidderId: '@g_santos_br',
      bidQty: 41,
      bidPrice: 56,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/14',
    path4: '/itemdetails/14',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt14,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 4 In Stock',
    text2: 'Warped Face',
    text3: 'What are you looking at?',
    artist: 'Gabriel Santos',
    artistId: '@g_santos_br',
    created: '5 Apr, 2021',
    itemArtist: 'Gabriel Santos',
    itemSize: '2500 x 2500',
    price: 150,
    highestBid: {
      bidderName: 'Andrew Kim',
      bidderId: '@Andrew.kim',
      bidQty: 152,
      bidPrice: 176,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/15',
    path4: '/itemdetails/15',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt15,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 5 In Stock',
    text2: 'Abstract BG',
    text3: 'A repetitive pattern of tigers',
    artist: 'Andrew Kim',
    artistId: '@kr_andrew',
    created: '10 Apr, 2021',
    itemArtist: 'Andrew Kim',
    itemSize: '2500 x 2500',
    price: 120,
    highestBid: {
      bidderName: 'Lisa Miller',
      bidderId: '@bp_lisa',
      bidQty: 122,
      bidPrice: 138,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/16',
    path4: '/itemdetails/16',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt16,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 1 In Stock',
    text2: 'Profile Pic',
    text3: 'Self Portrait with Complementary Colour',
    artist: 'Lisa Miller',
    artistId: '@bp_lisa',
    created: '11 Apr, 2021',
    itemArtist: 'Lisa Miller',
    itemSize: '2500 x 2500',
    price: 140,
    highestBid: {
      bidderName: 'Luoie Glover',
      bidderId: '@glamours',
      bidQty: 146,
      bidPrice: 157,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/17',
    path4: '/itemdetails/17',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt17,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 3 In Stock',
    text2: 'UFO Cat',
    text3: 'We all know cats are aliens',
    artist: 'Luoie Glover',
    artistId: '@glamours',
    created: '21 Apr, 2021',
    itemArtist: 'Luoie Glover',
    itemSize: '2500 x 2500',
    price: 40,
    highestBid: {
      bidderName: 'Oliver Twist',
      bidderId: '@twisters',
      bidQty: 41,
      bidPrice: 56,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/18',
    path4: '/itemdetails/18',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt18,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 2 In Stock',
    text2: 'Zombie Head',
    text3: 'Zombie boss icon #43',
    artist: 'Oliver Twist',
    artistId: '@twisters',
    created: '24 Apr, 2021',
    itemArtist: 'Oliver Twist',
    itemSize: '2500 x 2500',
    price: 50,
    highestBid: {
      bidderName: 'Marcos Hong',
      bidderId: '@pray_for',
      bidQty: 52,
      bidPrice: 67,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/19',
    path4: '/itemdetails/19',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt19,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 3 In Stock',
    text2: 'Catsz',
    text3: 'Happy cat. Today is her birthday!',
    artist: 'Marcos Hong',
    artistId: '@pray_for',
    created: '29 Apr, 2021',
    itemArtist: 'Marcos Hong',
    itemSize: '2500 x 2500',
    price: 80,
    highestBid: {
      bidderName: 'Kelly Clarkson',
      bidderId: '@sing_clark',
      bidQty: 82,
      bidPrice: 98,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/20',
    path4: '/itemdetails/20',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt20,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 2 In Stock',
    text2: 'Something Green',
    text3: 'Glow and Green',
    artist: 'Kelly Clarkson',
    artistId: '@sing_clark',
    created: '2 May, 2021',
    itemArtist: 'Kelly Clarkson',
    itemSize: '2500 x 2500',
    price: 40,
    highestBid: {
      bidderName: 'John Yang',
      bidderId: '@crazyrich',
      bidQty: 42,
      bidPrice: 55,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/21',
    path4: '/itemdetails/21',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt21,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 3 In Stock',
    text2: 'Thunder Rabbit',
    text3: 'Unusual rabbit with anger',
    artist: 'John Yang',
    artistId: '@crazyrich',
    created: '13 May, 2021',
    itemArtist: 'John Yang',
    itemSize: '2500 x 2500',
    price: 35,
    highestBid: {
      bidderName: 'Donald Brown',
      bidderId: '@d_tower',
      bidQty: 36,
      bidPrice: 45,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/22',
    path4: '/itemdetails/22',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt22,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 4 In Stock',
    text2: 'Friendzz',
    text3: 'Best Friends',
    artist: 'Donald Brown',
    artistId: '@d_tower',
    created: '15 May, 2021',
    itemArtist: 'Donald Brown',
    itemSize: '2500 x 2500',
    price: 60,
    highestBid: {
      bidderName: 'Daniel Steven',
      bidderId: '@dani',
      bidQty: 61,
      bidPrice: 78,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/23',
    path4: '/itemdetails/23',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt23,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 3 In Stock',
    text2: 'Blue Pirate',
    text3: 'Simple drawing of pirate',
    artist: 'Daniel Steven',
    artistId: '@dani',
    created: '18 May, 2021',
    itemArtist: 'Daniel Steven',
    itemSize: '2500 x 2500',
    price: 40,
    highestBid: {
      bidderName: 'John Manchester',
      bidderId: '@johnny_m',
      bidQty: 41,
      bidPrice: 55,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/24',
    path4: '/itemdetails/24',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt24,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 4 In Stock',
    text2: 'K-card game',
    text3: 'One, two, three, four, five... stop',
    artist: 'John Manchester',
    artistId: '@johnny_m',
    created: '22 May, 2021',
    itemArtist: 'John Manchester',
    itemSize: '2500 x 2500',
    price: 30,
    highestBid: {
      bidderName: 'Aiden Lee',
      bidderId: '@plan_ai',
      bidQty: 32,
      bidPrice: 38,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/25',
    path4: '/itemdetails/25',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt25,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 3 In Stock',
    text2: 'The Writer',
    text3: 'Write what you think about',
    artist: 'Aiden Lee',
    artistId: '@plan_ai',
    created: '26 May, 2021',
    itemArtist: 'Aiden Lee',
    itemSize: '2500 x 2500',
    price: 150,
    highestBid: {
      bidderName: 'Andy Jacks',
      bidderId: '@hacking_an',
      bidQty: 152,
      bidPrice: 180,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/26',
    path4: '/itemdetails/26',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt26,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 1 In Stock',
    text2: 'Bed Stories',
    text3: 'Peaceful bedtime with my dog',
    artist: 'Andy Jacks',
    artistId: '@hacking_an',
    created: '6 Jun, 2021',
    itemArtist: 'Andy Jacks',
    itemSize: '2500 x 2500',
    price: 150,
    highestBid: {
      bidderName: 'Diana Lee',
      bidderId: '@design_diana',
      bidQty: 155,
      bidPrice: 180,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/27',
    path4: '/itemdetails/27',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt27,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 3 In Stock',
    text2: 'Black Card',
    text3: 'The ultimate black card of the collection',
    artist: 'Diana Lee',
    artistId: '@design_diana',
    created: '13 Jun, 2021',
    itemArtist: 'Diana Lee',
    itemSize: '2500 x 2500',
    price: 80,
    highestBid: {
      bidderName: 'Debora Weinhelm',
      bidderId: '@wanda',
      bidQty: 82,
      bidPrice: 98,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/28',
    path4: '/itemdetails/28',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt28,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '2 Of 5 In Stock',
    text2: 'Sleeping Doge',
    text3: 'Maybe, he is at the MOON in dream',
    artist: 'Debora Weinhelm',
    artistId: '@wanda',
    created: '21 Jul, 2021',
    itemArtist: 'Debora Weinhelm',
    itemSize: '2500 x 2500',
    price: 70,
    highestBid: {
      bidderName: 'Clara Kim',
      bidderId: '@flying_panda',
      bidQty: 72,
      bidPrice: 87,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/29',
    path4: '/itemdetails/29',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt29,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '3 Of 4 In Stock',
    text2: 'Cat Face',
    text3: 'Cat Face - Face Cat - Cat Face',
    artist: 'Clara Kim',
    artistId: '@flying_panda',
    created: '28 Jul, 2021',
    itemArtist: 'Clara Kim',
    itemSize: '2500 x 2500',
    price: 80,
    highestBid: {
      bidderName: 'Joana Bestow',
      bidderId: '@walking_now',
      bidQty: 83,
      bidPrice: 99,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },

  {
    path1: '/',
    path2: '/profile',
    path3: '/itemdetails/30',
    path4: '/itemdetails/30',
    path5: '/',
    path6: '/',
    path7: '/',
    path8: '/',
    img1: SecNewListedArt30,
    img2: SecNewListedAuthors6,
    img3: SecNewListedAuthors1,
    img4: SecNewListedAuthors2,
    img5: SecNewListedAuthors3,
    img6: SecNewListedAuthors4,
    text1: '1 Of 2 In Stock',
    text2: 'Flowers Flying',
    text3: 'Letting the flowers fly at the last moment',
    artist: 'Joana Bestow',
    artistId: '@walking_now',
    created: '8 Oct, 2021',
    itemArtist: 'Joana Bestow',
    itemSize: '2500 x 2500',
    price: 80,
    highestBid: {
      bidderName: 'David Young',
      bidderId: '@DG_London',
      bidQty: 81,
      bidPrice: 99,
      placeBidDesc: 'The highest bid at the end of the auction will win',
    },
    history: [
      { qty: 15, timeAgo: '10 minutes ago' },
      { qty: 10, timeAgo: '23 minutes ago' },
    ],
    latestBids: [
      {
        bidderName: 'John Write',
        timeAgo: '5 minutes ago',
        subPrice: 0.32,
        price: 342,
      },
      {
        bidderName: 'Smith Doe',
        timeAgo: '7 minutes ago',
        subPrice: 45,
        price: 622,
      },
      {
        bidderName: 'Daniel Lee',
        timeAgo: '18 minutes ago',
        subPrice: 82,
        price: 947,
      },
    ],
  },
];

export default data;
