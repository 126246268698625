import TokenSwapContainer from '../containers/TvsSwap'

const TvsSwap = () => {

  return (
    <TokenSwapContainer />
  );
}

export default TvsSwap; 

